import React, { FunctionComponent } from "react";
import { graphql, useStaticQuery } from "gatsby";

import Layout from "../common/layout/layout";
import SEO from "../common/seo";
import Category from "../common/works/category";

export const resizeCover = graphql`
  fragment resizeCover on File {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 200, layout: CONSTRAINED)
    }
  }
`;

const WorksPage: FunctionComponent = () => {
  const query = useStaticQuery(graphql`
    query {
      image1960: file(
        relativePath: {
          eq: "works/paintings-1960/01 Painting Rivoluzione, Oil on canvas, cm 60x70.jpg"
        }
      ) {
        ...resizeCover
      }
      image1970: file(
        relativePath: {
          eq: "works/paintings-1970/01 Il primo, Oil on canvas, cm 100x100.jpg"
        }
      ) {
        ...resizeCover
      }
      image1980: file(
        relativePath: {
          eq: "works/paintings-1980/01 Pensieri diversi, Oil on canvas, cm 100x100.jpg"
        }
      ) {
        ...resizeCover
      }
      image1990: file(
        relativePath: {
          eq: "works/paintings-1990/01 Giubileo, Oil on canvas, cm 100x100.jpg"
        }
      ) {
        ...resizeCover
      }
      image2000: file(
        relativePath: {
          eq: "works/paintings-2000/01 Esplorazione, Oil on canvas, cm 30x30.jpg"
        }
      ) {
        ...resizeCover
      }
      imageCommissioned: file(
        relativePath: {
          eq: "works/commissioned/01 Stallone arabo, Oil on canvas, cm 50x70.jpg"
        }
      ) {
        ...resizeCover
      }
      imageDrawings: file(
        relativePath: {
          eq: "works/drypoints-and-drawings/01 Inquietante, Drypoint, cm 15x22.jpg"
        }
      ) {
        ...resizeCover
      }
      imageSculptures: file(
        relativePath: {
          eq: "works/sculptures/01 Bull I, Gold leaf gilded clay, cm 24.0x10.0x8.0, 1968.jpg"
        }
      ) {
        ...resizeCover
      }
      imageProjects: file(
        relativePath: {
          eq: "works/projects/59 Progetto 59, Pencil on paper.jpg"
        }
      ) {
        ...resizeCover
      }
      imageSketches: file(
        relativePath: {
          eq: "works/sketches/01 Cavalli in corsa, Pencil on paper.jpg"
        }
      ) {
        ...resizeCover
      }
      imagePosters: file(
        relativePath: { eq: "works/posters/01 Manifesto 2.jpg" }
      ) {
        ...resizeCover
      }
    }
  `);

  return (
    <Layout>
      <SEO title="Works" />
      <Category
        url="/works/paintings-1960/"
        title={`Paintings\n1960s`}
        image={query.image1960}
        loading={"eager"}
      />
      <Category
        url="/works/paintings-1970/"
        title={`Paintings\n1970s`}
        image={query.image1970}
        loading={"eager"}
      />
      <Category
        url="/works/paintings-1980/"
        title={`Paintings\n1980s`}
        image={query.image1980}
        loading={"eager"}
      />
      <Category
        url="/works/paintings-1990/"
        title={`Paintings\n1990s`}
        image={query.image1990}
        loading={"eager"}
      />
      <Category
        url="/works/paintings-2000/"
        title={`Paintings\n2000s`}
        image={query.image2000}
      />
      <Category
        url="/works/commissioned-works/"
        title="Commissioned works"
        image={query.imageCommissioned}
      />
      <Category
        url="/works/drypoints-and-drawings/"
        title="Drypoints and&nbsp;Drawings"
        image={query.imageDrawings}
      />
      <Category
        url="/works/sculptures/"
        title="Sculptures"
        image={query.imageSculptures}
      />
      <Category
        url="/works/projects/"
        title="Projects"
        image={query.imageProjects}
      />
      <Category
        url="/works/sketches/"
        title="Sketches"
        image={query.imageSketches}
      />
      <Category
        url="/works/posters/"
        title="Posters"
        image={query.imagePosters}
      />
    </Layout>
  );
};

export default WorksPage;
