import React, { FunctionComponent } from "react";
import { Link } from "gatsby";
import { GatsbyImage, GatsbyImageProps } from "gatsby-plugin-image";

import * as classes from "./category.module.scss";

interface CategoryProps {
  url: string;
  title: string;
  image: { childImageSharp: { gatsbyImageData: GatsbyImageProps["image"] } };
  loading?: "eager" | "lazy";
}

const Category: FunctionComponent<CategoryProps> = ({
  url,
  title,
  image,
  loading = "lazy",
}) => (
  <Link to={url}>
    <article className={classes.category}>
      <div className={classes.title}>
        <h2>{title}</h2>
      </div>
      <GatsbyImage
        image={image.childImageSharp.gatsbyImageData}
        className={classes.image}
        alt={title}
        loading={loading}
      />
    </article>
  </Link>
);

export default Category;
